import type { BBox, BoundingBox, DetectionLabel } from "@evercam/api/types"

export enum SiteAnalyticsMode {
  Cranes = "cranes",
  Detections = "detections",
  Segments = "segments",
}

export type SmartSearchOptionData = {
  id?: string
  type: SmartSearchQueryComponentType
  value: DetectionLabel | SmartSearchOperatorType | SmartSearchConditionData
}

export type SmartSearchQueryPart =
  | SmartSearchOptionData
  | SmartSearchQueryPart[]

export enum SmartSearchQueryComponentType {
  Condition = "condition",
  Object = "object",
  Area = "in_area",
  Time = "time",
  Operator = "operator",
}

export type SmartSearchQueryComponentData = {
  id: string
  type: SmartSearchQueryComponentType
  parts: SmartSearchQueryPart[]
}

export enum SmartSearchCondition {
  InArea = "in_area",
  Intersects = "intersects",
  Time = "time",
}

export type SmartSearchConditionData = {
  condition: SmartSearchCondition
  value: string | BoundingBox | DetectionLabel | Date
}

export type SmartSearchQuery = {
  returnTargets: SmartSearchQueryTargets[]
  rulesLogic: SmartSearchOperatorType
  rules: {
    logic: SmartSearchOperatorType
    conditions: {
      type: SmartSearchQueryConditionTypes
      target: SmartSearchQueryTargets
      operator?: SmartSearchQueryOperators
      attribute?: string
      value: string | number | string[]
      reference?: {
        target: SmartSearchQueryTargets
        attribute: string
        value: string | number | string[]
      }
      polygon?: BBox
    }[]
  }[]
  returnType: SmartSearchQueryReturnType
}

export enum SmartSearchQueryTargets {
  Tracking = "tracking",
  Segments = "segments",
}

export enum SmartSearchQueryReturnType {
  All = "all",
  FirstLastSeen = "first_last_seen",
}

export enum SmartSearchOperatorType {
  And = "AND",
  Or = "OR",
}

export enum SmartSearchQueryOperators {
  Near = "near",
  Far = "far",
  Intersects = "intersects",
  DoesNotIntersect = "does_not_intersect",
  Inside = "inside",
  Outside = "outside",
  DistanceBelow = "distance_below",
  DistanceAbove = "distance_above",
  Area = "in_area",
}

export enum SmartSearchQueryConditionTypes {
  Selection = "selection",
  Spatial = "spatial",
  Area = "area",
  Iou = "iou",
  Near = "near",
  Far = "far",
}
