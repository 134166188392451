import { AxiosError } from "axios"

export enum ErrorType {
  BadRequestError = "BadRequestError",
  NotFoundError = "NotFoundError",
  UnauthorizedError = "UnauthorizedError",
  GatewayTimeoutError = "GatewayTimeoutError",
  BadGatewayError = "BadGatewayError",
  ForbiddenError = "ForbiddenError",
  HlsError = "HlsError",
  SnapshotError = "SnapshotError",
  ConflictError = "ConflictError",
  InternalServerError = "InternalServerError",
  CanceledError = "CanceledError",
  UnhandledRejection = "UnhandledRejection",
  TypeError = "TypeError",
}

export enum EvercamApiErrorCode {
  BadArgument = "BAD_ARGUMENT",
  DeviceError = "DEVICE_ERROR",
  UnsupportedOperation = "UNSUPPORTED_OPERATION",
  QuotaExceeded = "QUOTA_EXCEEDED",
  InvalidCredentials = "INVALID_CREDENTIALS",
  ProviderAuthFailure = "PROVIDER_AUTH_FAILURE",
  ProviderEmailRequired = "PROVIDER_EMAIL_REQUIRED",
  PasswordReset = "PASSWORD_RESET",
  RequireShareRequest = "REQUIRE_SHARE_REQUEST",
  Generic = "GENERIC",
}

type _EvercamApiError = {
  code: EvercamApiErrorCode
  message: string
  details: string
  innerError?: _EvercamApiError
}

export type EvercamApiError = AxiosError<_EvercamApiError>
