import {
  CountryCode,
  CountryId,
  DateType,
  Nvr,
  PaginationParams,
  PowerType,
  Project,
  Region,
  Router,
  Schedule,
} from "@/types"

export type Kit = {
  alarms: KitAlarm[]
  countryCode: CountryCode
  countryId: CountryId
  countryName: string
  countryRegion: Region
  id?: number
  jobId: number
  name: string
  nvr: Nvr
  powerType: PowerType
  project: Project
  projectId: number
  router: Partial<Router>
  serial: string
  status: KitStatus
  powerSchedule: Schedule
}

export enum KitStatus {
  New = "new",
  Active = "active",
  Deployed = "deployed",
  Decommissioned = "decommissioned",
}

export enum KitAlarm {
  NvrOffline = "nvr_offline",
  UnknownCamera = "unknown_camera",
  NotRecordingCamera = "not_recording_camera",
  OfflineScheduled = "offline_scheduled",
  FullSD = "full_sd",
  FullStorage = "full_storage",
}

export enum KitMetricId {
  BatteryStats = "battery",
  PanelPowerStats = "panel_power",
}

export enum KitMetricPeriod {
  Last15Minutes = "now-15m",
  Last1Hour = "now-1h",
  Last24Hours = "now-24h",
  Last7Days = "now-7d",
  Last30Days = "now-30d",
  Last90Days = "now-90d",
}

export enum GrafanaMetricId {
  SbcCpuLoad = "SbcCpuLoad",
  SbcSystemLoad5MnAvg = "SbcSystemLoad5MnAvg",
  SbcSystemLoad15MnAvg = "SbcSystemLoad15MnAvg",
  SbcRamUsed = "SbcRamUsed",
  SbcSwapUsed = "SbcSwapUsed",
  SbcRootFsUsed = "SbcRootFsUsed",
  SbcCpuCoresTotal = "SbcCpuCoresTotal",
  SbcRamTotal = "SbcRamTotal",
  SbcSwapTotal = "SbcSwapTotal",
  SbcRootFsTotal = "SbcRootFsTotal",
  SbcUptime = "SbcUptime",
  SbcCpuStats = "SbcCpuStats",
  SbcRamStats = "SbcRamStats",
  SbcNetworkStats = "SbcNetworkStats",
  SbcTimeSyncStats = "SbcTimeSyncStats",
  SbcTemperatureStats = "SbcTemperatureStats",
  SbcTimeSyncDriftStats = "SbcTimeSyncDriftStats",
  CameraInfo = "CameraInfo",
  CameraRecordingStateTimeline = "CameraRecordingStateTimeline",
  CameraStreamInfo = "CameraStreamInfo",
  LocalStorage = "LocalStorage",
  StorageSpaceUsed = "StorageSpaceUsed",
  RouterTemperature = "RouterTemperature",
  RouterCpuLoad = "RouterCpuLoad",
  RouterRamUsed = "RouterRamUsed",
  RouterSystemLoad5MnAvg = "RouterSystemLoad5MnAvg",
  RouterSwapTotal = "RouterSwapTotal",
  RouterRamTotal = "RouterRamTotal",
  RouterUptime = "RouterUptime",
  RouterCpuCoresTotal = "RouterCpuCoresTotal",
  RouterSignalReliability = "RouterSignalReliability",
  RouterSINR = "RouterSINR",
  RouterRSRP = "RouterRSRP",
  RouterRSRQ = "RouterRSRQ",
  RouterRSSI = "RouterRSSI",
  RouterSignalStrengthStats = "RouterSignalStrengthStats",
  RouterSignalQualityStats = "RouterSignalQualityStats",
  RouterDataConsumptionStats = "RouterDataConsumptionStats",
  BatteryVoltageAndCurrentStats = "BatteryVoltageAndCurrentStats",
  PanelVoltageAndPowerStats = "PanelVoltageAndPowerStats",
  ExNvrStatus = "ExNvrStatus",
  ExNvrVersion = "ExNvrVersion",
  ExNvrMemoryUsage = "ExNvrMemoryUsage",
  ExNvrLogs = "ExNvrLogs",
}

export enum KitChartType {
  Stat = "stat",
  Gauge = "gauge",
  Graph = "graph",
  Table = "table",
  StateTimeline = "state-timeline",
  Logs = "logs",
  TimeSeries = "timeseries",
  BarChart = "barchart",
}

export type GrafanaDataSource = {
  type: string
  uid: string
}

export type GrafanaTarget = {
  expr: string
  refId: string
  datasourceId?: string
  intervalMs?: number
  maxDataPoints?: number
}

export type GrafanaPanel = {
  [key: string]: unknown
  id: GrafanaMetricId
  name: keyof GrafanaMetricId
  title: string
  description: string
  type: KitChartType
  datasource: GrafanaDataSource
  targets: GrafanaTarget[]
  maxDataPoints?: number
  interval?: string
  timeFrom?: string
  timeShift?: string
  options?: {
    maxDataPoints?: number
    interval?: string
  }
}

export type GrafanaDashboard = {
  [key: string]: unknown
  panels: GrafanaPanel[]
  time?: {
    from: string
    to: string
  }
  timepicker?: {
    refresh_intervals: string[]
    time_options: string[]
  }
}

export type GrafanaQueryOptions = {
  metricId: GrafanaMetricId
  host: string
  job?: string
  from?: string
  to?: string
  variables?: Record<string, string>
}

export type GrafanaTimeSeriesValue = number | string | null
export type GrafanaTimeSeriesValues = GrafanaTimeSeriesValue[][]

export type GrafanaFieldConfig = {
  displayNameFromDS?: string
  links?: string[]
  color?: string
  custom?: {
    lineInterpolation?: string
    fillOpacity?: number
    gradientMode?: string
    lineWidth?: number
    spanNulls?: boolean
    resultType?: string
  }
  thresholds?: {
    mode: string
    steps: Array<{
      color: string
      value: number | null
    }>
  }
  unit?: string
  decimals?: number
  interval?: number
}

export type GrafanaField = {
  name: string
  type: string
  typeInfo: {
    frame: string
    nullable?: boolean
  }
  config: GrafanaFieldConfig
  labels?: {
    [key: string]: string
  }
  values?: GrafanaTimeSeriesValues
}

export type GrafanaSchemaMeta = {
  type?: string
  typeVersion?: number[]
  custom?: {
    resultType?: string
    frameType?: string
  }
  stats?: Array<{
    displayName: string
    value: number
    unit?: string
  }>
  executedQueryString?: string
}

export type GrafanaSchema = {
  name?: string
  refId: string
  meta: GrafanaSchemaMeta
  fields: GrafanaField[]
}

export type GrafanaFrame = {
  schema: GrafanaSchema
  data: {
    values: GrafanaTimeSeriesValues
    nanos?: Array<number[] | null>
  }
}

export type KitMetricData = {
  type: KitChartType
  chartProps?: Record<string, unknown>
}

export type GrafanaQueryResult = {
  status: number
  frames: GrafanaFrame[]
}

export type GrafanaMetricData = KitMetricData & {
  results: {
    [key: string]: GrafanaQueryResult
  }
}

export type EvercamMetricData = KitMetricData & {
  stats: EvercamStats[]
}

export type GrafanaKitMetrics = Record<
  keyof typeof GrafanaMetricId,
  GrafanaMetricData
>

export type EvercamStats = {
  [k: string]: number | string
  timestamp: string
}

export type KitQueryParams = PaginationParams & {
  name: string
  serial: string
  status: KitStatus
}

export type AdminKit = {
  id: number
  name: string
  serial: string
  status: string
  powerType: PowerType
  location: string
  countryId: number
  countryName: string
  countryRegion: string
  nvr: Nvr
  router: {
    id: number
    serialNumber: number
    vpnUserId: string
    vpnPassword: string
    routerType: string
    routerUserId: string
    routerPassword: string
    routerHttpPort: number
    powerType: PowerType
    powerSchedule: Schedule
    vpnServer: string
    netbirdUrl: string
    sims: {
      id: number
      number: string
    }[]
  }
  project: {
    id: number
    exid: string
    name: string
    timezone: string
    status: string
    zohoId: string
    insertedAt: DateType
  }
}

export type KitCreatePayload = {
  projectId?: number
  countryCode?: string
  name: string
  status: KitStatus
  powerType: PowerType
  config: {
    region: Region
  }
}

export type KitUpdatePayload = {
  countryId: number
  name: string
  kitSerial: string
  kitStatus: KitStatus
  type: string
}
