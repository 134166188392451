import { PaginationParams } from "@/types"

export enum HddFormat {
  ExFat = "ExFAT",
  HikFs = "HikFS",
}

export enum HddType {
  Magnetic35 = '3.5" Magnetic',
  Magnetic25 = '2.5" Magnetic',
  Ssd25 = '2.5" SSD',
}

export enum HddState {
  Blank = "Blank",
  InUse = "In Use",
  ColdStorage = "Cold Storage",
}

export enum WarehouseLocationIndex {
  IEWarehouse = 1,
  Deployed = 2,
  CustomerStorage = 3,
  USWarehouse = 4,
  AUWarehouse = 5,
  UKWarehouse = 6,
}

export enum WarehouseLocation {
  IEWarehouse = "IE Warehouse",
  Deployed = "Deployed",
  CustomerStorage = "Customer Storage",
  USWarehouse = "US Warehouse",
  AUWarehouse = "AU Warehouse",
  UKWarehouse = "UK Warehouse",
}

export enum HddSize {
  TwoTB = "2 TB",
  FourTB = "4 TB",
  FiveTB = "5 TB",
  SixTB = "6 TB",
  EightTB = "8 TB",
  TenTB = "10 TB",
}

export type FootageCreateUpdatePayload = {
  cameraId: string
  startDate: string
  endDate: string
}

export type HDDCreateUpdatePayload = {
  size: string
  type: string
  format: string
  locationId: string
  serialNumber: string
  hddStatus: string
  shelfBlock: string
}

export type HDDsQueryParams = PaginationParams & {
  id: number
  location: string
  serialNumber: string
  shelfBlock: string
  format: string
  size: string
  type: string
  hddStatus: string
}
